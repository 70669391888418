// Style
import "./style/app.scss";

// App
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import { configBackendBaseurl, configKeycloakInitOptions } from "./config/app";
import { configlistItemsPerPageDefault } from "./config/list";
import axios from "axios";
import Keycloak from "keycloak-js";
import vSelect from "vue-select";
import Datepicker from "vue3-date-time-picker";
import Toast from "vue-toastification";
import i18n from "@/translation/i18n";
import VueFinalModal from "vue-final-modal";

// Bootstrap
import "bootstrap/js/src/dropdown";
import "bootstrap/js/src/tab";

// AdminLTE
import "admin-lte/build/js/Dropdown";
import "admin-lte/build/js/PushMenu";

// FontAwesome
import FontAwesomeIcon from "./fontAwesome";

// Keycloak and App init
let keycloak = Keycloak(configKeycloakInitOptions);

keycloak
  .init({ onLoad: configKeycloakInitOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      axios.defaults.baseURL = configBackendBaseurl;
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Authorization"] = "Bearer " + keycloak.token;
      axios.defaults.headers.common["X-Size"] = configlistItemsPerPageDefault;

      store.dispatch("fetchUser").then(() => {
        // Create app
        const app = createApp(App)
          .provide("keycloak", keycloak)
          .component("Datepicker", Datepicker)
          .component("v-select", vSelect)
          .component("font-awesome-icon", FontAwesomeIcon)
          .use(store)
          .use(router)
          .use(Toast)
          .use(i18n)
          .use(VueFinalModal);

        // apply global filters
        app.config.globalProperties.$filters = {
          formatDate(value) {
            return moment(String(value)).format("YYYY.MM.DD. HH:mm");
          },
          formatDateSeconds(value) {
            return moment(String(value)).format("YYYY.MM.DD. HH:mm:ss");
          },
        };
        app.mount("#app");
      });
    }

    //Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            //console.log("Token refreshed" + refreshed);
            axios.defaults.headers.common["Authorization"] = "Bearer " + keycloak.token;
          } else {
            //console.log("Token not refreshed, valid for " + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + " seconds");
          }
        })
        .catch(() => {
          console.log("Failed to refresh token");
        });
    }, 6000);
  })
  .catch(() => {
    console.log("Authenticated Failed");
  });
