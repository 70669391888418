import { createStore } from "vuex";
import { getData } from "@/helpers/dataHandle";
import { configKeycloakInitOptions } from "@/config/app";
import users from "./modules/users";
import organizations from "./modules/organizations";
import championships from "./modules/championships";
import events from "./modules/events";
import tasks from "./modules/tasks";
import expiredTasks from "./modules/expiredTasks";
import fileUploader from "./modules/fileUploader";

const store = createStore({
  modules: {
    users,
    organizations,
    championships,
    events,
    fileUploader,
    tasks,
    expiredTasks,
  },
  state: {
    user: null,
    loading: true,
    darkMode: false,
  },
  getters: {
    getUser: (state) => state.user,
    getLoading: (state) => state.loading,
  },
  actions: {
    async fetchUser({ commit }) {
      const res = await getData("user/props");

      if (res === 403) {
        commit("logout");

        return false;
      }

      commit("setUser", res);
      commit("setLoading", false);
    },
  },
  mutations: {
    setUser: (state, user) => (state.user = user),
    logout: (state) => {
      state.user = null;
      window.location =
        configKeycloakInitOptions.url +
        "/realms/" +
        configKeycloakInitOptions.realm +
        "/protocol/openid-connect/logout?redirect_uri=" +
        configKeycloakInitOptions.redirectUri;
    },
    setLoading: (state, loading) => (state.loading = loading),
    setDarkMode: (state) => (state.darkMode = !state.darkMode),
  },
});

export default store;
