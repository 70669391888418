const configSetAuthorities = [
  {
    id: "SUPERUSER",
    name: "MKI adminisztrátor",
  },
  {
    id: "SPORT_ADMIN",
    name: "Sportág adminisztrátor",
  },
  {
    id: "ORG_USER",
    name: "Szervezeti felhasználó",
  },
];

const configSetSports = [
  {
    id: 1,
    name: "labdarúgás",
  },
  {
    id: 2,
    name: "kosárlabda",
  },
  {
    id: 3,
    name: "kézilabda",
  },
  {
    id: 4,
    name: "jégkorong",
  },
  {
    id: 5,
    name: "vízilabda",
  },
];

const configSetGenders = [
  {
    id: "MALE",
    name: "Férfi",
  },
  {
    id: "FEMALE",
    name: "Női",
  },
];

const configSetNational = [
  {
    id: false,
    name: "Nem",
  },
  {
    id: true,
    name: "Igen",
  },
];

const configSetGenerateForm = [
  {
    id: false,
    name: "Nem",
  },
  {
    id: true,
    name: "Igen",
  },
];

export { configSetAuthorities, configSetSports, configSetGenders, configSetNational, configSetGenerateForm };
