const configlistItemsPerPageDefault = 12;

const configSortingUserSearch = [
  {
    orderby: "lastName.ASC",
    ordertext: "Név szerint növekvő (A-Z)",
  },
  {
    orderby: "lastName.DESC",
    ordertext: "Név szerint csökkenő (Z-A)",
  },
  {
    orderby: "email.ASC",
    ordertext: "E-mail szerint növekvő (A-Z)",
  },
  {
    orderby: "email.DESC",
    ordertext: "E-mail szerint csökkenő (Z-A)",
  },
];

const configSortingOrganizationSearch = [
  {
    orderby: "name.ASC",
    ordertext: "Név szerint növekvő (A-Z)",
  },
  {
    orderby: "name.DESC",
    ordertext: "Név szerint csökkenő (Z-A)",
  },
  {
    orderby: "shortName.ASC",
    ordertext: "Rövid név szerint növekvő (A-Z)",
  },
  {
    orderby: "shortName.DESC",
    ordertext: "Rövid név szerint csökkenő (Z-A)",
  },
];

const configSortingChampionshipSearch = [
  {
    orderby: "name.ASC",
    ordertext: "Név szerint növekvő (A-Z)",
  },
  {
    orderby: "name.DESC",
    ordertext: "Név szerint csökkenő (Z-A)",
  },
  {
    orderby: "startDate.ASC",
    ordertext: "Kezdődátum szerint növekvő (A-Z)",
  },
  {
    orderby: "startDate.DESC",
    ordertext: "Kezdődátum szerint csökkenő (Z-A)",
  },
];

const configSortingEventSearch = [
  {
    orderby: "eventDate.ASC",
    ordertext: "Dátum szerint növekvő (A-Z)",
  },
  {
    orderby: "eventDate.DESC",
    ordertext: "Dátum szerint csökkenő (Z-A)",
  },
];

export {
  configlistItemsPerPageDefault,
  configSortingUserSearch,
  configSortingOrganizationSearch,
  configSortingChampionshipSearch,
  configSortingEventSearch,
};
