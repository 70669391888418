<script>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { getData } from "@/helpers/dataHandle";
import { ref, onMounted } from "vue";

export default {
  name: "NavBar",
  props: {
    user: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const tasks = ref(0);
    const darkModeEnabled = true;

    function darkModeSwitch() {
      document.querySelector("body").classList.toggle("dark-mode");
      store.commit("setDarkMode");
    }

    function logout() {
      store.commit("logout");
    }

    onMounted(() => {
      if (props.user) {
        getData("task/count").then((res) => {
          if (res) {
            tasks.value = res > 99 ? "99+" : res;
          }
        });
      }
    });

    return {
      t,
      tasks,
      darkModeSwitch,
      logout,
      darkModeEnabled,
    };
  },
};
</script>

<template>
  <nav class="main-header navbar navbar-expand navbar-dark">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="" role="button">
          <font-awesome-icon icon="bars" />
        </a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li v-if="darkModeEnabled && user" class="nav-item">
        <a @click.prevent="darkModeSwitch" href="" class="nav-link" data-toggle="darkMode">
          <font-awesome-icon icon="circle-half-stroke" />
          <span class="d-none d-sm-inline-block ml-1">Sötét mód</span>
        </a>
      </li>

      <li v-if="user" class="nav-item dropdown">
        <router-link to="/tasks" class="nav-link">
          <font-awesome-icon icon="bell" />
          <span v-if="tasks > 0" class="badge badge-warning navbar-badge">
            {{ tasks }}
          </span>
          <span class="d-none d-sm-inline-block ml-1">Feladataim</span>
        </router-link>
      </li>

      <li class="nav-item">
        <a href="#" @click.prevent="logout" class="nav-link">
          <font-awesome-icon icon="sign-out-alt" />
          <span class="d-none d-sm-inline-block ml-1">Kijelentkezés</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.navbar-badge {
  right: 0;
  top: 0;
}
</style>
