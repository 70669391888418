import { useStore } from "vuex";

function isShow(authority) {
  let result = false;
  const store = useStore();
  const user = store.getters.getUser;

  if (authority === "all") return true;

  if (user) {
    user.authorities.forEach((userAuth) => {
      result = result || authority.includes(userAuth.authorityType);
    });

    return result;
  }
}

export { isShow };
