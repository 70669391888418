<script>
export default {
  name: "TheFooter",
};
</script>

<script setup>
import data from "../../package";
import { onMounted, ref } from "vue";
import { getData } from "@/helpers/dataHandle";

const backendVersion = ref("");

onMounted(async () => {
  const res = await getData("actuator/info");
  if (res) {
    backendVersion.value = res.build.version;
  }
});
</script>

<template>
  <footer class="main-footer">
    <div class="row justify-content-center align-items-center flex-wrap">
      <div class="col-md-3 text-center text-lg-left">
        <p class="mb-0">
          <small>
            <strong> &copy; {{ new Date().getFullYear() }} Szerencsejáték Zrt. </strong>
          </small>
        </p>
      </div>
      <div class="col-md-6 text-center">
        <p class="mb-0">
          <small>Probléma esetén forduljon a <span class="d-inline-block">Szerencsejáték Zrt.</span> kapcsolattartójához!</small>
        </p>
      </div>
      <div class="col-md-3 text-center text-lg-right">
        <p class="mb-0">
          <small>
            <strong>Verzió</strong> <span class="d-inline-block">TSP-UI{{ data.version }}-CORE{{ backendVersion }}</span>
          </small>
        </p>
      </div>
    </div>
  </footer>
</template>
