import { postData } from "@/helpers/dataHandle";
import { configSetGenders } from "@/config/sets";

const state = () => ({
  data: null,
  searchTerms: {
    name: null,
    startDate: null,
    endDate: null,
    sportId: null,
    gender: null,
    sorting: "name.ASC",
    active: null,
  },
  listLayout: "grid",
  page: 1,
  pageCount: 1,
  noResult: false,
});

const actions = {
  async getData({ commit, state, rootState }, searchTerms = state.searchTerms) {
    const res = await postData("championship/search", searchTerms, {
      "X-Page": state.page - 1,
    });

    state.noResult = res.data.length === 0;

    commit("setData", res.data);
    state.pageCount = res.totalPages ? parseInt(res.totalPages) : 1;

    state.data.forEach((championship) => {
      rootState.user.sports.forEach((sport) => {
        if (sport.id === championship.sportId) {
          championship.sportName = sport.name;
        }
      });

      configSetGenders.forEach((gender) => {
        if (gender.id === championship.gender) {
          championship.genderName = gender.name;
        }
      });
    });
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setSearchTerms(state, data) {
    state.searchTerms = data;
  },
  setSearchTermsSorting(state, data) {
    state.searchTerms.sorting = data;
  },
  setListLayout(state, data) {
    state.listLayout = data;
  },
  paginationHandle(state, data) {
    state.page = data;
  },
  setSearchTermsSport(state, data) {
    state.searchTerms.sportId = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
