import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    alias: ["/home"],
    name: "home",
    component: () => import("@/views/TasksIndex"),
    redirect: () => {
      return { name: "TasksSearch" };
    },
    meta: {
      auth: ["ALL"],
    },
  },
  {
    path: "/tasks",
    name: "TasksIndex",
    component: () => import("@/views/TasksIndex"),
    redirect: () => {
      return { name: "TasksSearch" };
    },
    meta: {
      auth: ["ALL"],
    },
    children: [
      {
        path: "",
        name: "TasksSearch",
        component: () => import("@/views/TasksSearch"),
        meta: {
          auth: ["ALL"],
        },
      },
      {
        path: "list",
        name: "TasksList",
        component: () => import("@/views/TasksList"),
        meta: {
          auth: ["ALL"],
        },
      },
    ],
  },
  {
    path: "/expired-tasks",
    name: "ExpiredTasksIndex",
    component: () => import("@/views/ExpiredTasksIndex"),
    redirect: () => {
      return { name: "ExpiredTasksSearch" };
    },
    meta: {
      auth: ["SUPERUSER", "SPORT_ADMIN"],
    },
    children: [
      {
        path: "",
        name: "ExpiredTasksSearch",
        component: () => import("@/views/ExpiredTasksSearch"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: "list",
        name: "ExpiredTasksList",
        component: () => import("@/views/ExpiredTasksList"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
    ],
  },
  {
    path: "/users",
    name: "UsersIndex",
    component: () => import("@/views/UsersIndex"),
    redirect: () => {
      return { name: "UsersSearch" };
    },
    meta: {
      auth: ["SUPERUSER", "SPORT_ADMIN"],
    },
    children: [
      {
        path: "search",
        name: "UsersSearch",
        component: () => import("@/views/UsersSearch"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: "list",
        name: "UsersList",
        component: () => import("@/views/UsersList"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: ":id",
        name: "UsersEdit",
        component: () => import("@/views/UsersForm"),
        props: true,
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: "create",
        name: "UsersCreate",
        component: () => import("@/views/UsersForm"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
    ],
  },
  {
    path: "/organizations",
    name: "OrganizationsIndex",
    component: () => import("@/views/OrganizationsIndex"),
    redirect: () => {
      return { name: "OrganizationsSearch" };
    },
    meta: {
      auth: ["SUPERUSER", "SPORT_ADMIN"],
    },
    children: [
      {
        path: "search",
        name: "OrganizationsSearch",
        component: () => import("@/views/OrganizationsSearch"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: "list",
        name: "OrganizationsList",
        component: () => import("@/views/OrganizationsList"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: ":id",
        name: "OrganizationsEdit",
        props: true,
        component: () => import("@/views/OrganizationsForm"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: "create",
        name: "OrganizationsCreate",
        component: () => import("@/views/OrganizationsForm"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
    ],
  },
  {
    path: "/championships",
    name: "ChampionshipsIndex",
    component: () => import("@/views/ChampionshipsIndex"),
    redirect: () => {
      return { name: "ChampionshipsSearch" };
    },
    meta: {
      auth: ["SUPERUSER", "SPORT_ADMIN"],
    },
    children: [
      {
        path: "search",
        name: "ChampionshipsSearch",
        component: () => import("@/views/ChampionshipsSearch"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: "list",
        name: "ChampionshipsList",
        component: () => import("@/views/ChampionshipsList"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: ":id",
        name: "ChampionshipsEdit",
        props: true,
        component: () => import("@/views/ChampionshipsForm"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: "create",
        name: "ChampionshipsCreate",
        component: () => import("@/views/ChampionshipsForm"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
    ],
  },
  {
    path: "/events",
    name: "EventsIndex",
    component: () => import("@/views/EventsIndex"),
    redirect: () => {
      return { name: "EventsSearch" };
    },
    meta: {
      auth: ["SUPERUSER", "SPORT_ADMIN", "ORG_USER"],
    },
    children: [
      {
        path: "search",
        name: "EventsSearch",
        component: () => import("@/views/EventsSearch"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN", "ORG_USER"],
        },
      },
      {
        path: "list",
        name: "EventsList",
        component: () => import("@/views/EventsList"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN", "ORG_USER"],
        },
      },
      {
        path: "create",
        name: "EventsCreate",
        component: () => import("@/views/EventsForm"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN", "ORG_USER"],
        },
      },
      {
        path: ":id",
        name: "EventsEdit",
        props: true,
        component: () => import("@/views/EventsForm"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN", "ORG_USER"],
        },
      },
    ],
  },
  {
    path: "/eventform/:id",
    name: "EventForm",
    component: () => import("@/views/EventForm"),
    props: true,
    meta: {
      auth: ["SUPERUSER", "SPORT_ADMIN", "ORG_USER"],
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("@/views/TheReports"),
    props: true,
    meta: {
      auth: ["SUPERUSER"],
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/TheProfile"),
    meta: {
      auth: ["ALL"],
    },
  },
  {
    path: "/marketing/:directoryId?",
    name: "MarketingFiles",
    component: () => import("@/views/MarketingFiles"),
    props: true,
    meta: {
      auth: ["SUPERUSER", "SPORT_ADMIN", "ORG_USER"],
    },
  },
  {
    path: "/data-upload",
    name: "dataUpload",
    component: () => import("@/views/DataUploadIndex"),
    meta: {
      auth: ["SUPERUSER", "SPORT_ADMIN"],
    },
    children: [
      {
        path: "",
        name: "dataUploadList",
        component: () => import("@/views/DataUploadList"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
      {
        path: ":id",
        name: "dataUploadFile",
        props: true,
        component: () => import("@/views/DataUploadFile"),
        meta: {
          auth: ["SUPERUSER", "SPORT_ADMIN"],
        },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("@/views/NotFound"),
    meta: {
      auth: ["ALL"],
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters.getUser) {
    const userAuthorities = store.getters.getUser.authorities;
    const toAuth = to.meta.auth;

    if (!toAuth) {
      next("/");
      return;
    }

    if (toAuth[0] === "ALL") {
      next();
      return;
    }

    userAuthorities.forEach((userAuth) => {
      if (toAuth.includes(userAuth.authorityType)) {
        next();
      }
    });

    userAuthorities.forEach((userAuth) => {
      if (!toAuth.includes(userAuth.authorityType)) {
        next("/");
      }
    });
  }
});

export default router;
