import { postData } from "@/helpers/dataHandle";

const state = () => ({
  data: null,
  searchTerms: {
    name: null,
    shortName: null,
    sportIds: null,
    sorting: "name.ASC",
  },
  listLayout: "grid",
  page: 1,
  pageCount: 1,
  noResult: false,
});

const actions = {
  async getData({ commit, state }, searchTerms = state.searchTerms) {
    const res = await postData("organization/search", searchTerms, {
      "X-Page": state.page - 1,
    });

    state.noResult = res.data.length === 0;

    commit("setData", res.data);
    state.pageCount = res.totalPages ? parseInt(res.totalPages) : 1;
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setSearchTerms(state, data) {
    state.searchTerms = data;
  },
  setSearchTermsSorting(state, data) {
    state.searchTerms.sorting = data;
  },
  setListLayout(state, data) {
    state.listLayout = data;
  },
  paginationHandle(state, data) {
    state.page = data;
  },
  setSearchTermsSport(state, data) {
    if (!data) {
      state.searchTerms.sportIds = null;
    } else {
      state.searchTerms.sportIds = [data];
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
