import { postData } from "@/helpers/dataHandle";

const state = () => ({
  data: null,
  searchTerms: {
    lastName: null,
    firstName: null,
    email: null,
    authorityType: null,
    sports: null,
    organizations: [],
    sorting: "lastName.ASC",
  },
  listLayout: "grid",
  page: 1,
  pageCount: 1,
  noResult: false,
});

const actions = {
  async getData({ commit, state }, searchTerms = state.searchTerms) {
    const res = await postData("user/search", searchTerms, {
      "X-Page": state.page - 1,
    });

    state.noResult = res.data.length === 0;

    commit(
      "setData",
      res.data.map(({ id, lastName, firstName, position, phoneNumber, email, authorities }) => ({
        id,
        lastName,
        firstName,
        position,
        phoneNumber,
        email,
        sports: authorities.map(({ sportId, organizationName, sportName }) => ({
          id: sportId,
          name: organizationName ? organizationName : sportName,
        })),
      }))
    );
    state.pageCount = res.totalPages ? parseInt(res.totalPages) : 1;
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setSearchTerms(state, data) {
    state.searchTerms = data;
  },
  setSearchTermsSorting(state, data) {
    state.searchTerms.sorting = data;
  },
  setListLayout(state, data) {
    state.listLayout = data;
  },
  paginationHandle(state, data) {
    state.page = data;
  },
  setSearchTermsSport(state, data) {
    if (!data) {
      state.searchTerms.sports = null;
    } else {
      state.searchTerms.sports = [data];
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
