import { postData } from "@/helpers/dataHandle";

const state = () => ({
  files: [],
  abort: false,
  controller: new AbortController(),
});

const getters = {
  getFiles: (state) => state.files,
  getLength: (state) => state.files.length,
  countDone: (state) => {
    let count = 0;
    const files = state.files;
    for (const i of Object.keys(files)) {
      if (files[i].done) count++;
    }
    return count;
  },
  countRemain: (state) => {
    let count = 0;
    const files = state.files;
    for (const i of Object.keys(files)) {
      if (!files[i].done) count++;
    }
    return count;
  },
};

const actions = {
  async submitFile({ state, commit }, { url, event, cb = null }) {
    if (!url) return;
    commit("enable");
    const rawFiles = event.target.files;
    if (!rawFiles) return;

    for (const i of Object.keys(rawFiles)) {
      const fileObject = {
        file: rawFiles[i],
        imgSrc: null,
        done: false,
        response: "wait",
      };

      commit("setFile", fileObject);
    }

    let files = state.files;

    for (const i of Object.keys(files)) {
      if (state.abort) return;

      if (!files[i].done) {
        files[i].response = "loading";
        const formData = new FormData();
        formData.append("uploadedFile", files[i].file, files[i].file.name);

        await postData(
          url,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          null,
          false,
          state.controller.signal
        )
          .then((res) => {
            if (res) {
              files[i].response = "done";
            } else {
              files[i].response = "error";
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            files[i].done = true;
          });
      }

      if (cb) cb();
    }
  },
};

const mutations = {
  setFile: (state, fileObject) => state.files.push(fileObject),
  enable: (state) => (state.abort = false),
  abort: (state) => {
    state.controller.abort();
    state.abort = true;
    state.files = [];
    state.controller = new AbortController();
  },
  reset: (state) => {
    state.files = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
