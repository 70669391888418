import { postData } from "@/helpers/dataHandle";

const state = () => ({
  data: null,
  searchTerms: {
    sportId: null,
    eventDate: null,
    homeOrganizationId: null,
    guestOrganizationId: null,
    championshipId: null,
    roundId: null,
    location: null,
    sorting: "name.ASC",
    active: null,
  },
  listLayout: "grid",
  page: 1,
  pageCount: 1,
  noResult: false,
  sportId: null,
  organizations: null,
  homeOrganizations: null,
  guestOrganizations: null,
  championships: null,
  rounds: null,
});

const actions = {
  async getData({ commit, state, rootState }, searchTerms = state.searchTerms) {
    const res = await postData("event/search", searchTerms, {
      "X-Page": state.page - 1,
    });

    state.noResult = res.data.length === 0;

    commit("setData", res.data);

    state.pageCount = res.totalPages ? parseInt(res.totalPages) : 1;

    state.data.forEach((event) => {
      rootState.user.sports.forEach((sport) => {
        if (sport.id === event.sportId) {
          event.sportName = sport.name;
        }
      });
    });
  },
  async getExtraData({ commit }, { currentValue }) {
    await postData(
      "organization/search/",
      {
        sportIds: [currentValue],
        sortBy: "name",
        sortOrder: "ASC",
      },
      {
        "X-Page": 0,
        "X-Size": 1000000,
      }
    ).then((res) => {
      if (res) {
        commit("setOrganizations", res.data);
        commit("setHomeOrganizations", res.data);
        commit("setGuestOrganizations", res.data);
      }
    });

    await postData(
      "championship/search/",
      {
        sportId: currentValue,
        sortBy: "name",
        sortOrder: "ASC",
      },
      {
        "X-Page": 0,
        "X-Size": 1000000,
      }
    ).then((res) => {
      if (res) {
        commit("setChampionships", res.data);
      }
    });
  },
  filterHomeOrganizations({ state, commit }, { currentValue }) {
    commit(
      "setHomeOrganizations",
      state.organizations.filter((item) => item.id !== currentValue)
    );
  },
  filterGuestOrganizations({ state, commit }, { currentValue }) {
    commit(
      "setGuestOrganizations",
      state.organizations.filter((item) => item.id !== currentValue)
    );
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setSearchTerms(state, data) {
    state.searchTerms = data;
  },
  setSearchTermsSorting(state, data) {
    state.searchTerms.sorting = data;
  },
  setListLayout(state, data) {
    state.listLayout = data;
  },
  paginationHandle(state, data) {
    state.page = data;
  },
  setSearchTermsSport(state, data) {
    state.sportId = data;
    state.searchTerms.sportId = data;
  },
  setOrganizations(state, data) {
    state.organizations = data;
  },
  setGuestOrganizations(state, data) {
    state.guestOrganizations = data;
  },
  setHomeOrganizations(state, data) {
    state.homeOrganizations = data;
  },
  setChampionships(state, data) {
    state.championships = data;
  },
  setRounds(state, data) {
    state.rounds = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
