import axios from "axios";
import { useToast } from "vue-toastification";
import { format } from "date-fns";
import i18n from "@/translation/i18n";
import store from "@/store";

const toast = useToast();
const { t } = i18n.global;

async function postData(url, data, headers, successMessage = null, isToast = true, signal = null) {
  store.commit("setLoading", true);

  if (data) {
    if (data.sorting) {
      data.sortBy = data.sorting.split(".")[0];
      data.sortOrder = data.sorting.split(".")[1];
    }

    if (data.sportId) {
      if (Array.isArray(data.sportId)) {
        data.sportId = data.sportId[0];
      }
    }

    if (data.organizations) {
      if (data.organizations.length === 0) {
        data.organizations = null;
      }
    }

    if (data.startDate && typeof data.startDate === "object") {
      data.startDate = format(data.startDate, "yyy-MM-dd");
    }

    if (data.endDate && typeof data.endDate === "object") {
      data.endDate = format(data.endDate, "yyy-MM-dd");
    }

    if (data.eventDate && typeof data.eventDate === "object") {
      data.eventDate = format(data.eventDate, "yyy-MM-dd");
    }
  }

  try {
    const request = await axios.post(url, data, { headers: headers, signal: signal });

    if ([200, 201].includes(request.status)) {
      store.commit("setLoading", false);
      if (isToast) {
        if (successMessage !== "") {
          toast.success(successMessage ? successMessage : t("messages.saveSuccess"));
        }
      }
      return request.data;
    }

    if (request.status === 206) {
      store.commit("setLoading", false);

      return {
        data: request.data,
        totalPages: request.headers["x-total-pages"] ? request.headers["x-total-pages"] : 1,
      };
    }
  } catch (err) {
    if (err.response) {
      if (isToast) {
        if (successMessage !== "") {
          if (err.response?.data.errorCode) {
            toast.error(t("messages." + err.response.data.errorCode));
          }

          if (err.response.status === 404) {
            toast.error(t("messages." + err.response.status));
          }
        } else {
          toast.error(t("messages.connectionError"));
        }
      }
    }

    return null;
  }
}

async function putData(url, data = null, successMessage = null) {
  store.commit("setLoading", true);

  try {
    const request = await axios.put(url, data);

    if (request.status === 204) {
      store.commit("setLoading", false);
      if (successMessage !== "") {
        toast.success(successMessage ? successMessage : t("messages.saveSuccess"));
      }

      return request;
    }
  } catch (err) {
    if (err.response) {
      if (err.response?.data.errorCode) {
        toast.error(t("messages." + err.response.data.errorCode));
      }

      if (err.response.status === 404) {
        toast.error(t("messages." + err.response.status));
      }
    } else {
      toast.error(t("messages.connectionError"));
    }

    return false;
  }
}

async function getData(url) {
  store.commit("setLoading", true);

  try {
    const request = await axios.get(url);

    if (request.status === 200) {
      store.commit("setLoading", false);

      return request.data;
    }
  } catch (err) {
    if (err.response) {
      if (err.response.status === 404) {
        toast.error(t("messages." + err.response.status));
      }
      if (err.response.status === 403) {
        return err.response.status;
      }
    }

    return false;
  }
}

async function deleteData(url) {
  store.commit("setLoading", true);

  try {
    const request = await axios.delete(url);

    if (request.status === 204) {
      store.commit("setLoading", false);
      toast.success(t("messages.deleteSuccess"));

      return request;
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data.errorCode.length) {
        toast.error(t("messages." + err.response.data.errorCode));
      } else if (err.response.status > 0) {
        toast.error(t("messages." + err.response.status));
      }
    } else {
      toast.error(t("messages.connectionError"));
    }
  }
}

export { postData, getData, putData, deleteData };
