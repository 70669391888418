import { postData } from "@/helpers/dataHandle";

const state = () => ({
  data: null,
  searchTerms: {
    sportId: null,
  },
  sportId: null,
});

const actions = {
  async getData({ commit, state } = state.searchTerms) {
    const res = await postData(
      "task/search",
      state.searchTerms,
      {
        "X-Page": state.page - 1,
      },
      null,
      false
    );

    commit("setData", res);

    state.data.forEach((item) => {
      if (item.taskType === "űrlap") item.uri = "eventform";
      if (item.taskType === "marketing") item.uri = "marketing";
    });
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setSearchTermsSport(state, data) {
    state.searchTerms.sportId = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
