// FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faSignOutAlt,
  faHome,
  faBuilding,
  faUserAlt,
  faCalendarAlt,
  faEdit,
  faTrash,
  faCheck,
  faCircleCheck,
  faBan,
  faPlus,
  faPen,
  faBell,
  faCircleHalfStroke,
  faPersonRunning,
  faFolderOpen,
  faFolder,
  faFilePdf,
  faFileImage,
  faFile,
  faComment,
  faClose,
  faSpinner,
  faCircleNotch,
  faUserPlus,
  faFloppyDisk,
  faUserSecret,
  faAnglesLeft,
  faEnvelope,
  faPhoneFlip,
  faEye,
  faLocationPin,
  faXmark,
  faTriangleExclamation,
  faCircleExclamation,
  faCirclePause,
  faGrip,
  faTableCells,
  faLocationDot,
  faPeopleGroup,
  faTrophy,
  faFontAwesomeFlag,
  faFlag,
  faBook,
  faUser,
  faLink,
  faCaretDown,
  faCaretUp,
  faUpload,
  faFolderPlus,
  faFolderMinus,
  faCalendarDay,
  faKey,
  faArrowUpRightFromSquare,
  faAngleDown,
  faAngleUp,
  faXmarksLines,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* add icons to the library */
library.add(
  faBars,
  faSignOutAlt,
  faHome,
  faBuilding,
  faUserAlt,
  faCalendarAlt,
  faEdit,
  faTrash,
  faCheck,
  faBan,
  faPlus,
  faPen,
  faBell,
  faCircleHalfStroke,
  faPersonRunning,
  faFolderOpen,
  faFolder,
  faFilePdf,
  faFileImage,
  faFile,
  faComment,
  faClose,
  faSpinner,
  faCircleNotch,
  faUserPlus,
  faFloppyDisk,
  faUserSecret,
  faAnglesLeft,
  faEnvelope,
  faPhoneFlip,
  faEye,
  faLocationPin,
  faXmark,
  faTriangleExclamation,
  faGrip,
  faTableCells,
  faLocationDot,
  faPeopleGroup,
  faTrophy,
  faFontAwesomeFlag,
  faFlag,
  faBook,
  faUser,
  faLink,
  faCaretDown,
  faCaretUp,
  faUpload,
  faFolderPlus,
  faFolderMinus,
  faCalendarDay,
  faKey,
  faArrowUpRightFromSquare,
  faCircleExclamation,
  faCircleCheck,
  faCirclePause,
  faAngleDown,
  faAngleUp,
  faXmarksLines
);

export default FontAwesomeIcon;
