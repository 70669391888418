<script>
export default {
  name: "FileUploader",
};
</script>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const filesList = computed(() => store.getters["fileUploader/getFiles"]);
const filesListLength = computed(() => store.getters["fileUploader/getLength"]);
const countDone = computed(() => store.getters["fileUploader/countDone"]);
const countRemain = computed(() => store.getters["fileUploader/countRemain"]);
const isMinimized = ref(false);

function closeWindow() {
  if (countRemain.value > 0) {
    if (confirm("Megszakítja a feltöltést?")) {
      store.commit("fileUploader/abort");
    } else {
      return;
    }
  }

  store.commit("fileUploader/reset");
}
</script>

<template>
  <div v-if="filesListLength > 0" :class="['file-upload-window', { minimized: isMinimized }]">
    <div class="file-upload-window-header">
      <div>
        <p v-if="countRemain > 0">{{ countRemain }} / {{ filesListLength }} fájl feltöltése</p>
        <p v-if="countRemain === 0">{{ countDone }} feltöltés befejeződött</p>
      </div>
      <div class="file-upload-window-header-actions">
        <div>
          <button @click="isMinimized = !isMinimized" type="button" class="btn btn-primary btn-sm" :title="isMinimized ? 'Teljes méret' : 'Kis méret'">
            <font-awesome-icon v-if="!isMinimized" icon="fa-solid fa-angle-down" />
            <font-awesome-icon v-if="isMinimized" icon="fa-solid fa-angle-up" />
          </button>
        </div>
        <div>
          <button @click="closeWindow()" type="button" class="btn btn-primary btn-sm" title="Bezárás">
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </button>
        </div>
      </div>
    </div>

    <div class="file-upload-window-body">
      <div class="table-responsive">
        <table class="table table-sm table-borderless">
          <tbody>
            <tr v-for="(item, index) in filesList" :key="index">
              <th scope="col" class="text-center">{{ index + 1 }}</th>
              <td>{{ item.file.name }}</td>
              <td class="text-center align-middle">
                <font-awesome-icon class="text-warning" v-if="item.response === 'wait'" icon="fa-solid fa-circle-pause" />
                <font-awesome-icon v-if="item.response === 'loading'" icon="fa-solid fa-circle-notch" spin />
                <font-awesome-icon class="text-success" v-if="item.response === 'done'" icon="fa-solid fa-circle-check" />
                <font-awesome-icon class="text-danger" v-if="item.response === 'error'" icon="fa-solid fa-circle-exclamation" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.file-upload-window {
  position: fixed;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 100;
  max-width: 550px;
  border-radius: 10px 10px 0 0;
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.4);

  &.minimized {
    .file-upload-window-body {
      display: none;
    }
  }

  &-header {
    padding: 10px;
    background-color: #394953;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div:first-child {
        margin-right: 5px;
      }
    }

    div:first-child {
      margin-right: 15px;
    }

    p {
      margin: 0;
      color: #fff;
    }
  }

  &-body {
    padding: 10px;
    max-height: 380px;
    overflow: auto;
    background-color: #f4f6f9;

    table.table {
      margin: 0;
    }
  }
}

.dark-mode {
  .file-upload-window-body {
    background-color: #343a40;
  }
}
</style>
