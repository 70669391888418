<template>
  <NavBar :user="user" />

  <SideBar :loading="loading" :user="user" />

  <div class="content-wrapper">
    <RouterView />
  </div>

  <FileUploader />

  <TheFooter />
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import NavBar from "@/components/NavBar";
import SideBar from "@/components/SideBar";
import TheFooter from "@/components/TheFooter";
import FileUploader from "@/components/FileUploader.vue";

export default {
  name: "App",
  components: {
    FileUploader,
    NavBar,
    SideBar,
    TheFooter,
  },
  setup() {
    const store = useStore();
    const user = store.getters.getUser;
    const loading = computed(() => {
      return store.getters.getLoading;
    });

    return {
      user,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.4);

  p {
    margin-bottom: 0;
  }
}
</style>
