import { postData } from "@/helpers/dataHandle";

const state = () => ({
  data: null,
  searchTerms: {
    sportId: null,
    type: "FORM",
    expiredForms: true,
  },
  sportId: null,
});

const actions = {
  async getData({ commit, state } = state.searchTerms) {
    const res = await postData(
      "task/search",
      state.searchTerms,
      {
        "X-Page": state.page - 1,
      },
      null,
      false
    );

    commit("setData", res);
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setSearchTermsSport(state, data) {
    state.searchTerms.sportId = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
