<script>
export default {
  name: "SideBar",
};
</script>
<script setup>
import { isShow } from "@/helpers/isShow";

defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
  user: Object,
});

const isLocationProd = () => {
  return window.location.hostname === "tsp.szrt.hu" ? "https://powerbi.szrt.hu" : "https://teszt-powerbi.szrt.hu";
};
</script>

<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <router-link to="/" class="brand-link">
      <img :src="require(`@/assets/img/tippmix_logo_white.png`)" alt="Tippmix" class="brand-image" />
      <span class="brand-text font-weight-light">Sportmarketing Platform</span>
    </router-link>

    <div v-if="user" class="sidebar">
      <div class="sidebar-body">
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">
            <font-awesome-icon icon="user" />
          </div>
          <div class="info">
            <router-link to="/profile" class="d-block"> {{ user.firstName }} {{ user.lastName }} </router-link>
          </div>
        </div>

        <nav class="mt-2">
          <ul class="nav nav-sidebar nav-flat flex-column" role="menu">
            <li v-if="isShow('all')" class="nav-item">
              <router-link to="/tasks" class="nav-link">
                <font-awesome-icon icon="bell" class="nav-icon" />
                <p>Feladatok</p>
              </router-link>
            </li>
            <li v-if="isShow(['SUPERUSER', 'SPORT_ADMIN'])" class="nav-item">
              <router-link to="/expired-tasks" class="nav-link">
                <font-awesome-icon icon="triangle-exclamation" class="nav-icon" />
                <p>Kitöltetlen űrlapok</p>
              </router-link>
            </li>
            <li v-if="isShow(['SUPERUSER', 'SPORT_ADMIN'])" class="nav-item">
              <router-link to="/organizations" class="nav-link">
                <font-awesome-icon icon="building" class="nav-icon" />
                <p>Szervezetek</p>
              </router-link>
            </li>
            <li v-if="isShow(['SUPERUSER', 'SPORT_ADMIN'])" class="nav-item">
              <router-link to="/users" class="nav-link">
                <font-awesome-icon icon="user-alt" class="nav-icon" />
                <p>Felhasználók</p>
              </router-link>
            </li>
            <li v-if="isShow(['SUPERUSER', 'SPORT_ADMIN'])" class="nav-item">
              <router-link to="/championships" class="nav-link">
                <font-awesome-icon icon="trophy" class="nav-icon" />
                <p>Bajnokságok</p>
              </router-link>
            </li>
            <li v-if="isShow(['SUPERUSER', 'SPORT_ADMIN', 'ORG_USER'])" class="nav-item">
              <router-link to="/events" class="nav-link">
                <font-awesome-icon icon="calendar-alt" class="nav-icon" />
                <p>Események</p>
              </router-link>
            </li>
            <li v-if="isShow(['SUPERUSER', 'SPORT_ADMIN', 'ORG_USER'])" class="nav-item">
              <router-link to="/marketing" class="nav-link">
                <font-awesome-icon icon="folder-open" class="nav-icon" />
                <p>Marketing</p>
              </router-link>
            </li>
            <li v-if="isShow(['SUPERUSER'])" class="nav-item d-none d-lg-block">
              <a :href="`${isLocationProd()}/reports/browse/TSP%20riportok`" class="nav-link" target="_blank">
                <font-awesome-icon icon="book" class="nav-icon" />
                <p>Riportok</p>
              </a>
            </li>
            <li v-if="isShow(['SUPERUSER', 'SPORT_ADMIN'])" class="nav-item">
              <router-link to="/data-upload" class="nav-link">
                <font-awesome-icon icon="upload" class="nav-icon" />
                <p>Adatfeltöltés</p>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <div class="sidebar-footer">
        <div class="sidebar-footer-logo">
          <img :src="require(`@/assets/img/szerencsejatek-logo-mini-inv.png`)" alt="Szerencsejáték Zrt." class="sidebar-footer-logo-small" />
          <img :src="require(`@/assets/img/szerencsejatek-zrt-logo-inv.png`)" alt="Szerencsejáték Zrt." class="sidebar-footer-logo-big" />
        </div>

        <div class="loading">
          <font-awesome-icon v-if="loading" icon="circle-notch" spin inverse />
        </div>
      </div>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
.main-sidebar {
  display: flex;
  flex-direction: column;

  .sidebar {
    flex-grow: 1;
  }
}

.layout-fixed .wrapper .sidebar {
  height: auto;
}

.sidebar-collapse .main-sidebar:not(:hover) {
  .brand-link .brand-image {
    transform: translateY(15px);
  }

  .sidebar .sidebar-footer .sidebar-footer-logo .sidebar-footer-logo-small {
    opacity: 1;
  }

  .sidebar .sidebar-footer .sidebar-footer-logo .sidebar-footer-logo-big {
    opacity: 0;
  }
}

.main-sidebar {
  .brand-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .brand-image {
      float: none;
      margin-top: 0;
      max-width: 100%;
      transform: translateY(0);
      transition: 0.3s transform ease-in-out;
    }
  }

  .user-panel {
    align-items: center;

    .image {
      color: #fff;
      display: flex;
      padding-left: 1rem;

      svg {
        width: 1.5rem;
        height: auto;
      }
    }
  }

  .sidebar {
    position: relative;
    display: flex;
    flex-direction: column;

    &-body {
      flex-grow: 1;
    }

    &-footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-logo {
        position: relative;
        text-align: center;

        img {
          display: inline-block;
          max-width: 100%;
          transition: 0.3s opacity ease-in-out;
          opacity: 1;

          &.sidebar-footer-logo-small {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            max-width: 30px;
            opacity: 0;
          }
        }
      }
    }
  }
}

.loading {
  margin-top: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
