const configBackendBaseurl = process.env.VUE_APP_BACKEND_BASE_URL;

const configKeycloakInitOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  onLoad: "login-required",
  redirectUri: window.location.origin,
};

export { configBackendBaseurl, configKeycloakInitOptions };
